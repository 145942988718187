import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useFetcher,
  useHref
} from 'react-router-dom'
import { loadingButtonClasses } from '@mui/lab/LoadingButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { ArrowDownSLineIcon } from '@oi/react/components/icons'

import type { ThemeOptions } from '@mui/material/styles'

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & {
  href: RouterLinkProps['to']
}>((props, ref) => {
  const routeFetcher = useFetcher()

  const isExternal = typeof props.href === 'string' && props.href.startsWith('http')
  const to = useHref(props.href)

  const onHover = React.useCallback(() => {
    if (!isExternal && to && to !== '/' && !to.startsWith('/#')) {
      routeFetcher.load(to)
    }
  }, [routeFetcher, to, isExternal])

  return (
    <RouterLink
      ref={ref}
      onMouseEnter={onHover}
      to={isExternal ? props.href : to}
      {...props} />
  )
})

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      primaryBackground: string
    }
  }

  interface PaletteOptions {
    custom: {
      primaryBackground: string
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true
  }
}

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'sans-serif',
      'Roboto'
    ].join(','),

    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontSize: 16,

    h1: {
      fontSize: 31,
      fontWeight: 600,
      lineHeight: '32px'
    },

    h2: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.2
    },

    // h3: {
    //   fontSize: 32,
    //   lineHeight: 1.5
    // },
    //
    // h4: {
    //   fontSize: 24,
    //   lineHeight: 1.5,
    //   fontWeight: 600
    // },
    //
    // h5: {
    //   fontSize: 18,
    //   lineHeight: 1.5,
    //   fontWeight: 600
    // },

    h6: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600
    },

    subtitle1: {
      fontSize: 20,
      lineHeight: 1.25,
      fontWeight: 600
    },

    subtitle2: {
      fontSize: 14,
      lineHeight: '0.875rem',
      fontWeight: 600
    },

    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400
    },

    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '0.875rem'
    },

    caption: {
      fontSize: 13,
      lineHeight: 0.875,
      fontWeight: 400
    },

    // overline: {
    //   fontSize: 12,
    //   lineHeight: 2.46
    // },

    button: {
      fontSize: 16,
      lineHeight: '26px',
      fontWeight: 600,
      textTransform: 'none'
    }
  },

  palette: {
    text: {
      primary: 'rgba(28, 7, 90, 1)',
      secondary: '#FFFFFF'
    },

    primary: {
      light: 'rgba(255, 0, 119, 0.5)',
      main: 'rgba(255, 0, 119, 1)'
    },

    secondary: {
      light: '#C3C3EE',
      main: '#0101F9',
      dark: '#1C075A'
    },

    divider: '#E5E5FF',

    background: {
      paper: 'rgb(245,245,255)'
    },

    custom: {
      primaryBackground: '#FEF6F8'
    }
  },

  shape: {
    borderRadius: 3
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          backgroundColor: '#FFFFFF',
          WebkitFontSmoothing: 'auto'
        },
        body: {
          minHeight: '100vh',
          backgroundColor: '#FFFFFF',

          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale'
        }
      }
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      }
    },

    MuiTypography: {
      defaultProps: {
        color: 'text.primary'
      }
    },

    MuiLink: {
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: LinkBehavior,
        underline: 'none'
      },
      styleOverrides: {
        underlineAlways: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightBold
        }),

        underlineNone: ({ theme }) => ({
          color: theme.palette.text.primary,

          '&:hover': {
            color: theme.palette.primary.main
          }
        }),

        underlineHover: ({ theme }) => ({
          color: theme.palette.text.primary,

          '&:hover': {
            color: theme.palette.primary.main
          }
        })
      }
    },

    MuiButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        disableElevation: true
      },
      variants: [
        {
          props: { variant: 'soft', color: 'secondary' },
          style: ({ theme }) => ({
            backgroundColor: '#F5F5FF',
            color: theme.palette.secondary.main,
            fontSize: 15,

            '&:hover': {
              backgroundColor: theme.palette.secondary.light
            }
          })
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: 100
        },

        contained: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            opacity: theme.palette.action.disabledOpacity
          },

          [`& .${loadingButtonClasses.loadingIndicator}`]: {
            color: theme.palette.common.white
          }
        }),

        text: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightRegular,
          borderRadius: theme.shape.borderRadius
        })
      }
    },

    MuiIconButton: {
      defaultProps: {
        LinkComponent: LinkBehavior
      },

      styleOverrides: {
        sizeSmall: ({ theme }) => ({
          fontSize: theme.typography.pxToRem(14)
        }),

        sizeMedium: ({ theme }) => ({
          padding: 5,
          fontSize: theme.typography.pxToRem(18)
        })
      }
    },

    MuiFab: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },

    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: 'none'
        },

        elevation: ({ theme }) => ({
          boxShadow: '0px 0px 6px 0px rgba(28,7,90,0.1)',
          backgroundColor: theme.palette.background.default
        })
      }
    },

    MuiChip: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
          borderRadius: 3
        })
      }
    },

    MuiOutlinedInput: {
      defaultProps: {
        size: 'medium'
      },

      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: '#F5F5FF',

          '&.Mui-focused': {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.secondary.main
            }
          },

          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'transparent'
          },

          [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'transparent'
          }
        }),

        colorSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.secondary.dark,

          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.secondary.light
          },

          '& input::placeholder': {
            color: theme.palette.secondary.dark
          }
        }),

        input: ({ theme }) => ({
          padding: theme.spacing(1.5, 2)
        }),

        inputSizeSmall: ({ theme }) => ({
          padding: theme.spacing(1, 1.5)
        })
      }
    },

    MuiAutocomplete: {
      defaultProps: {
        size: 'medium'
      },

      styleOverrides: {
        paper: {
          padding: 0
        },

        inputRoot: {
          flexWrap: 'inherit',
          padding: 0,

          '& > svg': {
            marginLeft: 14
          }
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 0
        }
      }
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36
        }
      }
    },

    // @ts-expect-error Picker is from separate package
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          padding: 0
        }
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -6
        },

        label: {
          marginLeft: 8
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          marginTop: 6
        })
      }
    },

    MuiRadio: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.main
        })
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'initial'
        }
      }
    },

    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDownSLineIcon
      },
      styleOverrides: {
        icon: {
          fontSize: 18
        }
      }
    }
  }
}
