import React from 'react'
import IllustrationContainer from '@oi/react/components/illustration-container'

import RegisterInfoSide from './info-side'
import RegisterForm from './register-form'

export interface RegisterProps {
  type: 'default' | 'incasso' | 'credit-check'
}

export default function RegisterRoute({ type }: RegisterProps) {
  const [showIllustration, setShowIllustration] = React.useState(false)

  const toggleIllustrationVisibility = React.useCallback(() => {
    setShowIllustration(!showIllustration)
  }, [showIllustration])

  return (
    <IllustrationContainer
      showIllustration={showIllustration}
      illustration={
        <RegisterInfoSide
          toggleIllustrationVisibility={toggleIllustrationVisibility}
          type={type} />
      }>
      <RegisterForm
        toggleIllustrationVisibility={toggleIllustrationVisibility}
        type={type} />
    </IllustrationContainer>
  )
}
