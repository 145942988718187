import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Navigate,
  Route,
  RouterProvider, ScrollRestoration
} from 'react-router-dom'
import { AUTH_ROUTE_LOADER_DATA_ID } from '@oi/constants/auth'
import FullScreenLoader from '@oi/react/components/full-screen-loader'
import AbilityProvider from '@oi/react/modules/auth'

import App, { appLoader } from './app'
import LoginRoute from './auth/login'
import RegisterRoute from './auth/register'

function defaultLoader() {
  return defer({})
}

const appRoutes = createBrowserRouter(createRoutesFromElements(
  <Route
    element={(
      <>
        <AbilityProvider />
        <ScrollRestoration />
      </>
    )}>
    <Route
      element={<LoginRoute />}
      loader={defaultLoader}
      path={'/login'} />

    <Route
      loader={defaultLoader}
      path={'/register'}>

      <Route
        element={<RegisterRoute type={'incasso'} />}
        loader={defaultLoader}
        path={'incasso'} />

      <Route
        element={<RegisterRoute type={'credit-check'} />}
        loader={defaultLoader}
        path={'credit-check'} />

      <Route
        element={<RegisterRoute type={'default'} />}
        index />

    </Route>

    <Route
      id={AUTH_ROUTE_LOADER_DATA_ID}
      loader={appLoader}>
      <Route
        element={<App />}
        id={'dashboard'}
        path={'/'}>
        <Route
          lazy={() => import('./app/dashboard')}
          loader={defaultLoader}
          index />

        <Route
          lazy={() => import('./app/credit-case-new')}
          loader={defaultLoader}
          path={'credit-cases/new/*'} />

        <Route
          lazy={() => import('./app/credit-case')}
          loader={defaultLoader}
          path={'credit-cases/:id/*'} />

        <Route
          lazy={() => import('./app/credit-cases')}
          path={'credit-cases'} />

        <Route
          lazy={() => import('./app/credit-checks-search')}
          loader={defaultLoader}
          path={'credit-checks/search/:query?'} />

        <Route
          lazy={() => import('./app/credit-checks-pay')}
          loader={defaultLoader}
          path={'credit-checks/pay'} />

        <Route
          lazy={() => import('./app/credit-check')}
          path={'credit-checks/:id'} />

        <Route
          lazy={() => import('./app/credit-checks')}
          path={'credit-checks'} />

        <Route
          lazy={() => import('./app/admin')}
          loader={defaultLoader}
          path={'admin'} />

        <Route
          lazy={() => import('./app/company')}
          loader={defaultLoader}
          path={'company'} />

        <Route
          lazy={() => import('./app/account')}
          loader={defaultLoader}
          path={'account'} />
      </Route>

      <Route
        element={<Navigate to={'/'} />}
        path={'*'} />
    </Route>
  </Route>
))

export default function Routes() {
  return (
    <RouterProvider
      fallbackElement={<FullScreenLoader />}
      router={appRoutes} />
  )
}
