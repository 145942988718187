import type { FieldValidations, ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

export function validateSameAs<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: FieldValidations['sameAs']): ValidationResult {
  const field = options && formValues[options.field]

  return {
    valid: shouldValidate(value)
      ? value === formValues[field]
      : true,
    message: intl.formatMessage({
      id: 'fields.error.same-as',
      defaultMessage: 'Should be the same as {fieldName}!'
    }, {
      fieldName: options && options.fieldLabel
    })
  }
}
