import React from 'react'
import { menuItemClasses } from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import { TooltipProps } from '@mui/material/Tooltip'
import ExtendedTooltip from '@oi/react/components/extended-tooltip'

import { useSpotlightAPI } from '../spotlight.component'

interface SpotlightItemProps extends Pick<TooltipProps, 'title' | 'placement'>{
  spotlightKey: string,
  hideItemFrame?: boolean
  children: React.ReactElement | Array<React.ReactElement>
}

const ChildrenOverlay =styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.modal + 3,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}))

const SpotlightContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideItemFrame'
})<{ hideItemFrame?: boolean }>(({ theme, hideItemFrame }) => ({
  position: 'relative',
  zIndex: theme.zIndex.modal + 2,
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,

  ...(!hideItemFrame && {
    margin: theme.spacing(-2, -3)
  }),

  [`& .${menuItemClasses.root}.${menuItemClasses.selected}`]: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),

    '& svg': {
      fill: theme.palette.secondary.main
    }
  }
}))

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideItemFrame'
})<{ hideItemFrame?: boolean }>(({ theme, hideItemFrame }) => ({

  ...(!hideItemFrame && {
    padding: theme.spacing(2, 3)
  })

}))

export default function SpotlightItem({
  spotlightKey, placement, title, hideItemFrame = true, children
}: SpotlightItemProps) {
  const { isInSpotlight } = useSpotlightAPI(spotlightKey)

  if (isInSpotlight) {
    return (
      <SpotlightContainer hideItemFrame={hideItemFrame}>
        <ExtendedTooltip
          placement={placement}
          title={title}
          variant={'tutorial'}
          open>
          <StyledDiv hideItemFrame={hideItemFrame}>
            <ChildrenOverlay />
            {children}
          </StyledDiv>
        </ExtendedTooltip>
      </SpotlightContainer>
    )
  }

  return children
}
