import React from 'react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import type { SystemCssProperties } from '@mui/system/styleFunctionSx'

import { BaseField, GroupOfFields } from '../fields.interface'
import FieldBase from './field.base.component'
import { fieldClasses } from './field.classes'

interface GroupContainerProps {
  direction?: 'row' | 'column'
  gridTemplateColumns?: SystemCssProperties['gridTemplateColumns']
  subGroup?: boolean
}

export interface FieldGroupProps extends GroupOfFields, GroupContainerProps {
  children: React.ReactNode
}

const StyledGroupContainer = styled('div', {
  shouldForwardProp: (prop: string) => !['direction', 'gridTemplateColumns', 'subGroup'].includes(prop)
})<GroupContainerProps>(({ theme, direction = 'row', gridTemplateColumns = '1fr 1fr', subGroup }) => ({
  position: 'relative',

  ...(direction !== 'column' && ({
    display: 'grid',
    gridColumnGap: theme.spacing(subGroup ? 2 : 4),

    ...theme.unstable_sx({
      gridTemplateColumns
    })
  }))
}))

export default function FieldGroup({
  name,
  required,
  prefix = 'field.group',
  label,
  labelVariant,
  subGroup,
  direction,
  gridTemplateColumns,
  children: childrenProp,
  className
}: React.PropsWithChildren<FieldGroupProps>) {
  const children = React.useMemo(() => {
    return Array.isArray(childrenProp)
      ? childrenProp.filter(Boolean)
      : [childrenProp]
  }, [childrenProp])

  return (
    <FieldBase
      className={className}
      label={label}
      labelVariant={labelVariant}
      name={name}
      prefix={prefix}
      required={required}
      isGroup>
      <StyledGroupContainer
        className={fieldClasses.groupContainer}
        direction={direction}
        gridTemplateColumns={gridTemplateColumns}
        subGroup={subGroup}>
        {React.Children.map(children, (child: React.ReactElement<BaseField>, index) => {
          if (!React.isValidElement(child)) {
            return child

          } else if (child.props.hidden) {
            return child
          }

          return (
            <Stack
              key={child.props.name}
              className={fieldClasses.groupChild}>
              {child}
            </Stack>
          )
        })}
      </StyledGroupContainer>
    </FieldBase>
  )
}
