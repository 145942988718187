import React from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

/**
 * Does the same as "useSearchParams" but instead merges the new values with the existing
 */
export function useMergeSearchParams(): [URLSearchParams, (params: URLSearchParamsInit) => void] {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSetSearchParams = React.useCallback((params: URLSearchParamsInit) => {
    setSearchParams((prevSearchParams) => {
      const newParams: Record<string, string> = {}
      for (const [key, value] of prevSearchParams.entries()) {
        newParams[key] = value
      }

      for (const [key, value] of Object.entries(params)) {
        if (value !== null) {
          newParams[key] = value

        } else {
          delete newParams[key]
        }
      }

      return newParams
    })
  }, [setSearchParams])

  return [searchParams, handleSetSearchParams]
}
