import { GetMessagesResponse, Language } from './language.interfaces'

const STORAGE_KEY = '___oi_interface_language'
const messagesCache = {} as {
  [key: string]: GetMessagesResponse
}

const supportedLanguageKeys = Object.keys(Language).map((key) => key.toLowerCase()) as string[]
const fallbackLanguage = Language.En.toLowerCase()

export function addMessagesToCache(language: Language, messages: GetMessagesResponse['messages'], dateMessages: object) {
  messagesCache[language] = {
    messages,
    dateMessages: dateMessages
  }
}

export function getMessagesFromCache(language: Language): GetMessagesResponse {
  return messagesCache[language]
}

export function getInitialLocale() {
  const language = localStorage.getItem(STORAGE_KEY)

  if (!language && navigator.language) {
    const browserLanguage = navigator.language.toLowerCase().split('-').shift()

    // Check if the browser language is one we support
    if (browserLanguage && supportedLanguageKeys.includes(browserLanguage)) {
      return browserLanguage
    }
  }

  return language || fallbackLanguage
}

export function storeNewLocale(newLocale: string) {
  localStorage.setItem(STORAGE_KEY, newLocale)
}
