import { IntlShape, useIntl } from 'react-intl'

import { RegisterProps } from '../register.component'

function baseRegistrationPoints(intl: IntlShape) {
  return {
    1: {
      order: '01.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.point-1.title',
        defaultMessage: 'Create an account'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.point-1.description',
        defaultMessage: 'Simply create a free account with Online Incasso. Don\'t worry, nothing has happened yet.'
      })
    }
  }
}

function defaultRegistrationPoints(intl: IntlShape) {
  return {
    2: {
      order: '02.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.default.point-2.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.default.point-2.description',
        defaultMessage: 'Once your account has been created you can start a direct debit.'
      })
    },
    3: {
      order: '03.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.default.point-3.title',
        defaultMessage: 'Fill in data'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.default.point-3.description',
        defaultMessage: 'When starting a direct debit, you will have to enter the details of the creditor and yourself.'
      })
    },
    4: {
      order: '04.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.default.point-4.title',
        defaultMessage: 'Pay'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.default.point-4.description',
        defaultMessage: 'Once you have completed everything, the payment process can begin. You pay a fixed price per journey.'
      })
    },
    5: {
      order: '05.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.default.point-5.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.default.point-5.description',
        defaultMessage: 'We will take over from you for now and send a letter to the creditor.'
      })
    }
  }
}

function incassoRegistrationPoints(intl: IntlShape) {
  return {
    2: {
      order: '02.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-2.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-2.description',
        defaultMessage: 'Once your account has been created you can start a direct debit.'
      })
    },
    3: {
      order: '03.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-3.title',
        defaultMessage: 'Fill in data'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-3.description',
        defaultMessage: 'When starting a direct debit, you will have to enter the details of the creditor and yourself.'
      })
    },
    4: {
      order: '04.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-4.title',
        defaultMessage: 'Pay'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-4.description',
        defaultMessage: 'Once you have completed everything, the payment process can begin. You pay a fixed price per journey.'
      })
    },
    5: {
      order: '05.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-5.title',
        defaultMessage: 'Incasso start'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.incasso.point-5.description',
        defaultMessage: 'We will take over from you for now and send a letter to the creditor.'
      })
    }
  }
}

function creditRegistrationPoints(intl: IntlShape) {
  return {
    2: {
      order: '02.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-2.title',
        defaultMessage: 'Request a credit report'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-2.description',
        defaultMessage: 'Find the company you want to check creditworthiness for.'
      })
    },
    3: {
      order: '03.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-3.title',
        defaultMessage: 'Pay'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-3.description',
        defaultMessage: 'You pay a fixed price of €7.50 for the credit report request.'
      })
    },
    4: {
      order: '04.',
      title: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-4.title',
        defaultMessage: 'Received credit card'
      }),
      description: intl.formatMessage({
        id: 'routes.register.side-info.credit.point-4.description',
        defaultMessage: 'After payment you will receive the report within ... (X hours/working days).'
      })
    }
  }
}

export function useRegistrationTooltipPoints(type: RegisterProps['type']): Record<number, {
  order: string,
  title: string,
  description: string
}> {
  const intl = useIntl()

  let points = baseRegistrationPoints(intl)

  if (type === 'default') {
    points = {
      ...points,
      ...defaultRegistrationPoints(intl)
    }
  }

  if (type === 'incasso') {
    points = {
      ...points,
      ...incassoRegistrationPoints(intl)
    }
  }

  if (type === 'credit-check') {
    points = {
      ...points,
      ...creditRegistrationPoints(intl)
    }
  }

  return points
}
