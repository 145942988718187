import React from 'react'
import { styled } from '@mui/material/styles'

interface useSpotlightAPI {
  setSequence: (sequence: Array<string>) => void
  visibleSpotlightKey: string | undefined
  nextStep: () => void
  hasMore: boolean
  close: () => void
  steps: number
  step: number
}
const SpotlightContext = React.createContext<useSpotlightAPI>({
  setSequence: () => {
    // Do nothing
  },
  visibleSpotlightKey: undefined,
  nextStep: () => {
    // Do nothing
  },
  hasMore: false,
  close: () => {
    // Do nothing
  },
  steps: 0,
  step: 0
})

export function useSpotlightAPI(spotlightKey?: string) {
  const { visibleSpotlightKey, ...rest } = React.useContext(SpotlightContext)

  return {
    isInSpotlight: spotlightKey === visibleSpotlightKey,
    ...rest
  }
}

export function useSpotlightSequenceAPI(sequence: Array<string>) {
  const { setSequence } = React.useContext(SpotlightContext)

  return {
    startSequence: () => setSequence(sequence)
  }
}

const Overlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.modal,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
}))

export default function Spotlight({ children }: React.PropsWithChildren) {
  const sequenceRef = React.useRef<Array<string>>([])
  const [sequenceIdx, setSequenceIdx] = React.useState<number>(-1)

  const steps = sequenceRef.current?.length || 0
  const step = sequenceIdx + 1

  const hasMore = steps > step

  const setSequence = React.useCallback( (sequence: Array<string>) => {
    sequenceRef.current = sequence
    setSequenceIdx(sequence ? 0: -1 )
  }, [])

  const close = React.useCallback( () => {
    sequenceRef.current = []
    setSequenceIdx(-1)
  }, [])

  const nextStep = React.useCallback(() => {
    if (hasMore) {
      setSequenceIdx(sequenceIdx + 1)
    } else {
      close()
    }
  }, [hasMore, close, sequenceIdx])

  const visibleSpotlightKey = sequenceRef.current?.at(sequenceIdx)

  return (
    <SpotlightContext.Provider
      value={{
        setSequence,
        visibleSpotlightKey,
        nextStep,
        hasMore,
        steps,
        step,
        close
      }}>
      {visibleSpotlightKey && <Overlay />}
      {children}
    </SpotlightContext.Provider>
  )
}
