import { defer, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import type {
  CreditCase,
  CreditCasesApiCreditCasesCreateOneRequest,
  CreditCasesApiCreditCasesDeleteOneRequest,
  CreditCasesApiCreditCasesUpdateOneRequest,
  CreditCasesApiInvoicesCreateOneRequest, CreditCasesApiInvoicesDeleteOneRequest,
  CreditCasesApiInvoicesUpdateOneRequest,
  FindCreditCase,
  Invoice
} from './open-api'
import type { FetchQueryOptions } from '@tanstack/react-query'

import { creditCaseAPi } from './api.constants'
import { DEFAULT_LIST_OPTIONS, unwrapResponse, useWrapMethod } from './api.internal'
import { buildFiltersQueryKey, ListOptions, queryClient } from './api.methods'

export function listCreditCases(options: ListOptions = DEFAULT_LIST_OPTIONS, filters = {}) {
  return queryClient.ensureQueryData({
    queryKey: ['credit-cases', options.offset, options.limit, options.query, ...buildFiltersQueryKey(filters)],
    queryFn: () => unwrapResponse(creditCaseAPi.creditCasesQueryMany({ ...options, ...filters }))
  })
}

export function getCreditCaseQueryKey(id: number | string) {
  return ['credit-case', `${id}`]
}

function getCreditCaseQueryOptions(id: string): FetchQueryOptions<FindCreditCase> {
  return {
    queryKey: getCreditCaseQueryKey(id),
    queryFn: () => unwrapResponse(creditCaseAPi.creditCasesFindById({ id }))
  }
}

export function getCreditCaseLoader(id: string) {
  return defer({
    data: queryClient.ensureQueryData(getCreditCaseQueryOptions(id))
  })
}

export function useCreditCase() {
  const params = useParams<{ id: string }>()

  return useQuery(getCreditCaseQueryOptions(params.id as string))
}

export function useCreditCaseTimeline() {
  const params = useParams<{ id: string }>()

  return useQuery({
    queryKey: getCreditCaseQueryKey(params.id as string).concat(['timeline']),
    queryFn: () => unwrapResponse(creditCaseAPi.caseTimelineStepQueryMany({
      creditCaseId: params.id as string
    }))
  })
}

export function useUpdateCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesUpdateOneRequest,
    CreditCase
  >(
    (params: CreditCasesApiCreditCasesUpdateOneRequest) => creditCaseAPi.creditCasesUpdateOne(params),
    (params: CreditCasesApiCreditCasesUpdateOneRequest) => getCreditCaseQueryKey(params.id)
  )
}

export function useCreateCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesCreateOneRequest,
    CreditCase
  >(
    (params: CreditCasesApiCreditCasesCreateOneRequest) => creditCaseAPi.creditCasesCreateOne(params),
    // Make sure to insert it into the cache so the next page load is v fast
    (params, data) => getCreditCaseQueryKey(data.id)
  )
}

export function useDeleteCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesDeleteOneRequest,
    CreditCase
  >((params: CreditCasesApiCreditCasesDeleteOneRequest) => creditCaseAPi.creditCasesDeleteOne(params))
}

export function useCreateCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesCreateOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesCreateOneRequest) => creditCaseAPi.invoicesCreateOne(params),
    (params, data) => getCreditCaseQueryKey(data.creditCaseId),
    (existing, data: Invoice) => ({
      ...existing,
      invoices: [
        ...(existing as never as FindCreditCase).invoices,
        data
      ]
    })
  )
}

export function useUpdateCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesUpdateOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesUpdateOneRequest) => creditCaseAPi.invoicesUpdateOne(params),
    (params, data) => getCreditCaseQueryKey(data.creditCaseId),
    (existing, data: Invoice) => ({
      ...existing,
      invoices: (existing as never as FindCreditCase).invoices.map((invoice) => {
        if (invoice.id === data.id) {
          return data
        }

        return invoice
      })
    })
  )
}

export function useDeleteCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesDeleteOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesDeleteOneRequest) => creditCaseAPi.invoicesDeleteOne(params),
    (params, data) => getCreditCaseQueryKey(data.creditCaseId),
    (existing, data: Invoice, params) => ({
      ...existing,
      invoices: (existing as never as FindCreditCase).invoices.filter(({ id }) => `${id}` !== params.id)
    })
  )
}
