import React from 'react'

interface RegisterIconProps {
  height?: number,
  width?: number
}

export default function RegisterIcon({ height = 283, width = 267 }:RegisterIconProps) {

  return (
    <svg
      fill={'none'}
      height={height}
      viewBox={'0 0 283 267'}
      width={width}
    >
      <path
        d={'M137.312 212.534c41.498 0 75.139-33.641 75.139-75.139s-33.641-75.14-75.139-75.14c-41.499 0-75.14 33.642-75.14 75.14s33.642 75.139 75.14 75.139Z'}
        fill={'#FF0076'}
      />
      <path
        d={'M93.596 91.754c0-4.477 4.783-8.16 6.121-12.202 1.389-4.19-.224-9.914 2.371-13.395 2.618-3.523 8.69-3.778 12.285-6.341 3.559-2.536 5.67-8.128 9.946-9.49 4.125-1.31 9.169 1.956 13.747 1.956 4.578 0 9.617-3.267 13.742-1.955 4.272 1.357 6.387 6.948 9.95 9.489 3.596 2.563 9.672 2.818 12.285 6.34 2.591 3.486.982 9.21 2.367 13.396 1.338 4.038 6.122 7.725 6.122 12.202s-4.784 8.16-6.122 12.203c-1.389 4.189.224 9.914-2.367 13.395-2.618 3.518-8.689 3.778-12.285 6.341-3.559 2.536-5.669 8.128-9.95 9.489-4.125 1.311-9.169-1.951-13.742-1.951s-9.617 3.267-13.747 1.951c-4.272-1.357-6.387-6.949-9.946-9.489-3.595-2.563-9.671-2.819-12.285-6.341-2.59-3.486-.982-9.21-2.37-13.395-1.344-4.043-6.123-7.725-6.123-12.203Z'}
        fill={'#0C0C0B'}
      />
      <path
        d={'m146.226 125.443.224-25.077-16.767.37v24.707h16.543Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M129.687 103.468s2.622 4.697 8.383 4.697c5.761 0 8.383-5.405 8.383-5.405l-16.766.708Z'}
        fill={'#963232'}
      />
      <path
        d={'M119.066 80.886a2.63 2.63 0 0 0-1.344.05c-.438.133-.831.38-1.142.713-.429.475-.662 1.101-.749 1.731-.233 1.673.498 3.368 1.649 4.615 1.152 1.251 2.687 2.097 4.267 2.764.425-2.125-.21-4.25-.475-6.35-.187-1.472-.425-3.103-2.206-3.523Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M138.066 59.518c-11.047 0-13.623 4.957-15.999 9.352-2.047 3.796-2.293 8.26-2.22 12.554.068 3.897.397 7.904 2.179 11.39 2.851 5.587 6.149 13.112 16.04 13.112 9.891 0 13.637-9.681 14.51-14.67 1.284-7.333 1.978-15.798-.832-22.962-2.713-6.884-8.209-8.78-13.673-8.78l-.005.004Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M130.496 78.624c1.549-.489 2.645-1.402 2.444-2.046-.201-.64-1.621-.763-3.17-.275-1.554.49-2.645 1.403-2.444 2.047.201.64 1.621.763 3.17.274ZM148.916 78.313c.201-.64-.89-1.558-2.444-2.046-1.553-.49-2.969-.366-3.17.274-.201.64.891 1.553 2.444 2.042 1.553.489 2.969.365 3.17-.274v.004ZM130.364 86.016c.808-.082 1.361-1.174 1.229-2.435-.128-1.26-.891-2.211-1.705-2.129-.808.082-1.361 1.174-1.228 2.435.127 1.261.89 2.211 1.704 2.13ZM146.125 85.793c.814 0 1.476-1.028 1.476-2.294 0-1.265-.662-2.293-1.476-2.293-.813 0-1.475 1.028-1.475 2.293 0 1.266.662 2.294 1.475 2.294Z'}
        fill={'#282828'}
      />
      <path
        d={'M138.144 85.641a.402.402 0 0 0-.151-.15.4.4 0 0 0-.206-.046.385.385 0 0 0-.333.233c-.736 1.818-2.353 6.492.653 6.35 3.216-.15 1.01-4.665.041-6.391l-.004.004Z'}
        fill={'#963232'}
      />
      <path
        d={'M134.315 97.73s3.751.772 7.502-.105c0 0 .324 2.741-3.632 2.737-3.614 0-3.87-2.632-3.87-2.632Z'}
        fill={'#fff'}
      />
      <path
        d={'M154.563 80.886c.448-.1.909-.082 1.348.05.438.137.831.384 1.142.718.434.475.662 1.1.749 1.731.229 1.672-.498 3.367-1.649 4.614-1.151 1.248-2.686 2.097-4.267 2.76-.425-2.12.21-4.25.471-6.35.182-1.476.42-3.107 2.202-3.528l.004.005Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M181.828 160.018s-6.537-30.733-12.792-34.826c-6.249-4.094-22.582-5.962-22.582-5.962a56.36 56.36 0 0 1-16.767 0s-21.782-6.716-28.699-8.863c-4.404 2.887-15.945 9.064-14.921 13.162.43 1.713 19.631 11.453 19.631 11.453l4.377 28.956-4.222 56.901h64.435l-4.217-56.901 15.762-3.911-.005-.009Z'}
        fill={'#0201FB'}
      />
      <path
        d={'m166.049 163.928 12.97-3.216 5.172 21.573 4.769 35.438s5.492 13.779.585 15.383c-4.906 1.608-5.948-5.533-5.948-5.533s-.146 2.769-1.782 2.476c-1.635-.292-.968-9.219-.968-9.219l-13.478-39.043-1.32-17.863v.004Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M119.413 80.456c1.991-.165 3.659-1.567 4.851-3.139 1.193-1.571 2.061-3.367 3.335-4.87 2.824-3.326 7.251-4.82 11.449-6.168 3.408 4.994 9.169 8.274 15.291 8.612.311-3.577-1.147-7.044-2.586-10.348-.539-1.242-1.096-2.512-1.978-3.55-1.019-1.196-2.43-2.019-3.892-2.63-5.697-2.404-12.463-1.805-17.991.94-1.293.64-2.531 1.398-3.577 2.376-1.28 1.201-2.23 2.704-3.02 4.253-2.239 4.35-1.946 9.663-1.878 14.524h-.004Z'}
        fill={'#0C0C0B'}
      />
      <path
        d={'M119.289 85.445s-1.421 16.246 13.806 16.246'}
        stroke={'#0201FB'}
        strokeMiterlimit={10}
        strokeWidth={1.716}
      />
      <path
        d={'M119.152 87.138c.805-.064 1.376-1.145 1.275-2.415-.1-1.27-.834-2.248-1.639-2.185-.805.064-1.376 1.145-1.275 2.416.1 1.27.834 2.248 1.639 2.184ZM133.602 103.519c1.963 0 3.554-.818 3.554-1.828 0-1.009-1.591-1.827-3.554-1.827-1.963 0-3.554.818-3.554 1.827 0 1.01 1.591 1.828 3.554 1.828Z'}
        fill={'#0201FB'}
      />
      <path
        d={'M124.944 140.876a4.344 4.344 0 0 1-4.34-4.34 4.345 4.345 0 0 1 4.34-4.341 4.345 4.345 0 0 1 4.341 4.341h-1.828a2.517 2.517 0 0 0-2.513-2.513 2.516 2.516 0 0 0-2.512 2.513 2.516 2.516 0 0 0 2.512 2.512v1.828Z'}
        fill={'#fff'}
      />
      <path
        d={'M127.941 140.524a1.142 1.142 0 1 0 0-2.283 1.142 1.142 0 0 0 0 2.283Z'}
        fill={'#fff'}
      />
      <path
        clipRule={'evenodd'}
        d={'M118.782 83.718c.265-3.654 1.521-10.7 1.521-10.7l-.648 10.623-.877.077h.004Z'}
        fill={'#0201FB'}
        fillRule={'evenodd'}
      />
      <path
        d={'M140.843 249.133C77.029 250.732 23.998 200.3 22.399 136.482 20.799 72.663 71.232 19.636 135.05 18.037c63.814-1.599 116.846 48.833 118.445 112.652'}
        stroke={'#0201FB'}
        strokeMiterlimit={10}
        strokeWidth={34.89}
      />
      <path
        d={'M246.012 260.038c13.6-.343 24.355-11.645 24.012-25.246-.342-13.601-11.645-24.355-25.246-24.012-13.6.342-24.355 11.645-24.012 25.246.343 13.6 11.645 24.354 25.246 24.012Z'}
        fill={'#FF0076'}
      />
      <path
        d={'M39.361 85.386c-.118-.288-.278-.932.836-1.877 1.115-.946 4.573 1.626 4.985 1.407.41-.22.06-1.65.562-4.043.502-2.394 3.284-3.299 3.732-2.682.448.617-1.092 4.157-.132 5.825.703 1.229 1.073 4.056 1.215 5.464 5.062 2.457 36.142 19.8 50.423 20.887 0 0-6.442 12.143-11.85 13.674-5.214 1.471-39.94-22.806-45.22-28.005-1.686-.453-4.92-2.413-6.21-3.44-1.338-1.07-.364-2.495-.364-2.495-.768-1.064.62-2.622.62-2.622-.16-1.307 1.399-2.093 1.399-2.093h.004Z'}
        fill={'#CE5353'}
      />
    </svg>

  )
}
