import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'

import type { BaseField } from '../fields.interface'
import type { InputBaseComponentProps } from '@mui/material/InputBase'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'

export interface FieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<OutlinedInputProps, 'required' | 'type' | 'disabled' | 'color' | 'placeholder' | 'startAdornment' | 'endAdornment' | 'autoComplete'>, BaseField {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>

  // Internal only
  inputComponent?: React.ElementType<InputBaseComponentProps>
}

export default function FieldText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  inputComponent,
  ...outlinedInputProps
}: FieldTextProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      required={required}>
      <OutlinedInput
        {...outlinedInputProps}
        ref={field.ref}
        disabled={field.disabled || controller.disabled}
        inputComponent={inputComponent}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        onKeyDown={controller.onKeyDown}
        required={required}
        value={field.value || ''}
        fullWidth
      />
    </FieldBase>
  )
}
