import type { ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

function getStringScore(givenString: string) {
  let score = 0

  if (!givenString) {
    return score
  }

  // award every unique letter until 5 repetitions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const letters = {} as any

  for (let i = 0; i < givenString.length; i += 1) {
    letters[givenString[i]] = (letters[givenString[i]] || 0) + 1
    score += 5.0 / letters[givenString[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(givenString),
    lower: /[a-z]/.test(givenString),
    upper: /[A-Z]/.test(givenString),
    nonWords: /\W/.test(givenString)
  }

  let variationCount = 0
  for (const check in variations) {
    // @ts-expect-error something weird
    variationCount += variations[check] === true ? 2 : 0
  }

  score += (variationCount - 2) * 10

  return parseInt(`${score}`, 10)
}

export function validateScore<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>): ValidationResult {
  return {
    valid: shouldValidate(value)
      ? getStringScore(value) > 70
      : true,
    message: intl.formatMessage({
      id: 'fields.error.password-score',
      defaultMessage: 'Password is to weak!'
    })
  }
}
