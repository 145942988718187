import React from 'react'
import { styled } from '@mui/material/styles'
import LogoWhiteSvg from '@oi/react/assets/logo/white.svg'

export interface FullScreenLoaderProps {
  withDelay?: boolean
}

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.secondary.dark,
  zIndex: theme.zIndex.modal + 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: '100vh',
  width: '100vw',

  '& img': {
    width: 250
  }
}))

function FullScreenLoader({ withDelay = false }: FullScreenLoaderProps, ref: React.Ref<HTMLDivElement>) {
  const [visible, setVisible] = React.useState(!withDelay)

  React.useEffect(() => {
    let timeout: NodeJS.Timeout

    if (withDelay) {
      // If the app takes longer then 150ms then show the loader
      timeout = setTimeout(() => setVisible(true), 150)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (!visible) {
    return <div ref={ref} />
  }

  return (
    <Root
      ref={ref}
      data-test-id={'components.full-screen-loader'}>
      <img
        alt={'Online Incasso'}
        src={LogoWhiteSvg} />
    </Root>
  )
}

export default React.forwardRef(FullScreenLoader)
