import React from 'react'

import type { FieldValue } from './fields.interface'

import { FormState } from './fields.interface'

export const ControllerContext = React.createContext({})

export interface FieldControllerApi {
  namePrefix?: string
  loading?: boolean
  skeleton?: boolean
  disabled?: boolean

  onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void
}

export function useFieldsController<Data extends object = FieldValue[]>() {
  return React.useContext(ControllerContext) as FieldControllerApi & {
    // Not used yet
    defaultState?: FormState<Data>
  }
}

export function useFieldName<TName>(name: TName): TName {
  const controller = useFieldsController()

  if (!controller || !controller.namePrefix) {
    return name
  }

  return `${controller.namePrefix}${name}` as TName
}
