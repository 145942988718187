export enum Claim {
  AlphaFeatures = 'alpha-features',
  BetaFeatures = 'beta-features',

  All = 'all',
  Me = 'me',

  // Only added when user is defined as admin
  Admin = 'admin',

  CreditCase = 'credit-case',
  CreditCheck = 'credit-check',
  Company = 'company'
}
