import { FetchQueryOptions, useQuery } from '@tanstack/react-query'

import type { DashboardStats } from './open-api'

import { dashboardApi } from './api.constants'
import { unwrapResponse } from './api.internal'

function getDashboardStatsQueryOptions(): FetchQueryOptions<DashboardStats> {
  return {
    queryKey: ['dashboard'],
    queryFn: () => unwrapResponse(dashboardApi.stats())
  }
}

export function useDashboardStats() {
  return useQuery(getDashboardStatsQueryOptions())
}
