import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Building4LineIcon, User3LineIcon, WindowLineIcon } from '@oi/react/components/icons'

export function useMenuItems(showMenuTutorial = false) {
  return [{
    key: 'menu.user.information',
    icon: <User3LineIcon fontSize={'small'} />,
    to: '/account',
    selected: showMenuTutorial,
    label: (
      <FormattedMessage
        defaultMessage={'My user information'}
        id={'menu.user.information'} />
    )
  }, {
    key: 'menu.user.company',
    icon: <Building4LineIcon fontSize={'small'} />,
    to: '/company',
    selected: false,
    label: (
      <FormattedMessage
        defaultMessage={'My company details'}
        id={'menu.user.company'} />
    )
  }, {
    key: 'menu.user.accounting',
    icon: <WindowLineIcon fontSize={'small'} />,
    to: '/credit-checks',
    selected: false,
    label: (
      <FormattedMessage
        defaultMessage={'Link accounting system'}
        id={'menu.user.accounting'} />
    )
  }]
}

export function useLinkItems() {
  return [{
    to: '/',
    exact: true,
    label: (
      <FormattedMessage
        defaultMessage={'Dashboard'}
        id={'menu.dashboard'} />
    )
  }, {
    to: '/credit-cases',
    exact: false,
    label: (
      <FormattedMessage
        defaultMessage={'Credit cases'}
        id={'menu.credit-cases'} />
    )
  }, {
    to: '/credit-checks',
    exact: false,
    label: (
      <FormattedMessage
        defaultMessage={'Credit checks'}
        id={'menu.credit-checks'} />
    )
  }]
}
