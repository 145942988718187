import React from 'react'
import { PureAbility, subject } from '@casl/ability'
import { createContextualCan } from '@casl/react'

export const AbilityContext = React.createContext<PureAbility>(null as never)

export const Can = createContextualCan(AbilityContext.Consumer)

export {
  subject
}
