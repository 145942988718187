import React from 'react'
import { FormProvider, Path, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import type { FieldsRef, FieldValidationSchema } from './fields.interface'
import type { DefaultValues, FieldValues } from 'react-hook-form'
import type { ObjectShape } from 'yup'

import { ControllerContext, FieldControllerApi } from './fields.hooks'
import FieldError, { FieldErrorProps } from './types/field.error.component'

export interface FieldsControllerProps<Fields> extends Omit<FieldControllerApi, 'onKeyDown'>, FieldErrorProps {
  validationSchema?: FieldValidationSchema<Fields>
  defaultValues?: DefaultValues<Fields>

  onEnter?: (event: React.KeyboardEvent<HTMLElement>) => void
}

function FieldsController<Fields extends FieldValues>({
  namePrefix,

  skeleton = false,
  loading,
  disabled,
  validationSchema,
  defaultValues,

  error,
  errorMessages,

  onEnter,

  children
}: React.PropsWithChildren<FieldsControllerProps<Fields>>, ref: React.ForwardedRef<FieldsRef<Fields>>) {
  const methods = useForm<Fields>({
    resolver: validationSchema ? yupResolver(yup.object(validationSchema as ObjectShape)) as never : undefined,
    mode: 'onBlur',
    defaultValues
  })

  const handleGetValues = React.useCallback(async () => {
    const fields = Object.keys(methods.control._fields)

    // Trigger validations
    const isValid = await methods.trigger(fields as Path<Fields>[])
    if (isValid) {
      return { isValid, values: methods.getValues() }
    }

    return { isValid }

  }, [methods])

  React.useImperativeHandle(ref, () => ({
    getValues: handleGetValues
  } as FieldsRef<Fields>))

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key.toLowerCase() === 'enter' && onEnter) {
      event.preventDefault()

      onEnter(event)
    }
  }, [onEnter])

  return (
    <ControllerContext.Provider
      value={{
        namePrefix,
        loading,
        disabled,
        onKeyDown: handleKeyDown
      }}>
      <FormProvider {...methods}>
        {children}

        <FieldError
          error={error}
          errorMessages={errorMessages} />
      </FormProvider>
    </ControllerContext.Provider>
  )
}

export default React.forwardRef(FieldsController)
