import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getMe } from '@oi/react/modules/api'
import { useOnMountEffect } from '@oi/react/modules/utils'

export function useRedirectUserWhenLoggedIn() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleNavigate = React.useCallback(() => {
    if (searchParams.has('from')) {
      navigate(searchParams.get('from') as string)

    } else {
      navigate('/')
    }
  }, [navigate, searchParams])

  useOnMountEffect(() => {
    (async () => {
      try {
        await getMe()
        handleNavigate()
      } catch {
        // Do nothing
      }
    })()
  })

  return {
    handleNavigate
  }
}
