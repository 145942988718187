import React from 'react'

interface TutorialIconProps {
  large?: boolean
}

export default function TutorialIcon({ large }: TutorialIconProps) {
  let size = {
    height: 50,
    width: 54
  }
  if (large) {
    size = {
      height: 74,
      width: 74
    }
  }
  return (
    <svg
      viewBox={'0 0 54 50'}
      {...size}
    >
      <path
        d={'M25.903 39.942c7.868 0 14.246-6.378 14.246-14.246s-6.378-14.247-14.246-14.247-14.247 6.379-14.247 14.247 6.379 14.246 14.247 14.246Z'}
        fill={'#FF0076'}
      />
      <path
        d={'m20.9 30.81-2.667-.662-.98 4.09-.904 6.717s-1.041 2.611-.111 2.916c.93.304 1.128-1.049 1.128-1.049s.028.525.338.47c.31-.055.183-1.748.183-1.748l2.553-7.4.46-3.334Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M17.568 17.076c0-.848.907-1.547 1.16-2.312.264-.794-.041-1.88.45-2.54.496-.667 1.647-.716 2.329-1.201.674-.481 1.074-1.54 1.885-1.799.782-.248 1.738.371 2.605.371.868 0 1.824-.619 2.605-.37.81.257 1.211 1.317 1.886 1.798.682.485 1.833.534 2.329 1.201.491.661.186 1.746.45 2.54.253.765 1.16 1.464 1.16 2.313 0 .848-.907 1.547-1.16 2.312-.264.794.041 1.88-.45 2.54-.496.667-1.647.716-2.329 1.201-.675.481-1.074 1.54-1.886 1.799-.782.248-1.738-.37-2.605-.37-.866 0-1.823.619-2.605.37-.81-.258-1.211-1.318-1.885-1.799-.682-.485-1.833-.534-2.329-1.201-.491-.66-.186-1.746-.45-2.54-.254-.766-1.16-1.464-1.16-2.313Z'}
        fill={'#0C0C0B'}
      />
      <path
        d={'m27.544 23.463.042-4.754-3.178.07v4.684h3.136Z'}
        fill={'#CE5353'} />
      <path
        d={'M24.41 19.298s.496.89 1.588.89c1.093 0 1.59-1.024 1.59-1.024l-3.179.135v-.001Z'}
        fill={'#963232'}
      />
      <path
        d={'M22.396 15.017a.503.503 0 0 0-.471.145.612.612 0 0 0-.142.328c-.044.317.094.638.312.875.217.237.509.398.808.524.08-.403-.039-.806-.09-1.204-.035-.279-.08-.588-.418-.667l.001-.001Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M25.998 10.967c-2.093 0-2.582.939-3.032 1.773-.389.72-.434 1.565-.421 2.38.013.738.075 1.498.412 2.16.541 1.058 1.166 2.485 3.04 2.485 1.875 0 2.585-1.835 2.751-2.78.244-1.391.375-2.995-.158-4.354-.514-1.305-1.556-1.664-2.591-1.664Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M24.562 14.588c.294-.092.502-.266.463-.387-.038-.122-.307-.145-.6-.053-.295.093-.502.267-.464.388.038.121.307.145.601.052ZM28.054 14.53c.039-.122-.169-.296-.463-.388-.294-.092-.563-.07-.601.052-.038.122.169.295.463.388.294.092.563.069.601-.053ZM24.537 15.989c.153-.016.258-.222.233-.462-.024-.238-.169-.419-.323-.403-.154.016-.257.222-.233.461.024.24.169.42.323.404ZM27.525 15.947c.154 0 .28-.194.28-.435s-.126-.435-.28-.435c-.154 0-.279.194-.279.435 0 .24.125.435.28.435Z'}
        fill={'#282828'}
      />
      <path
        d={'M26.012 15.918a.07.07 0 0 0-.029-.028.072.072 0 0 0-.039-.009.07.07 0 0 0-.038.014.076.076 0 0 0-.025.031c-.14.345-.446 1.23.123 1.204.61-.028.191-.884.007-1.212h.001Z'}
        fill={'#963232'}
      />
      <path
        d={'M25.286 18.209s.71.146 1.422-.02c0 0 .062.52-.688.519-.684 0-.734-.499-.734-.499Z'}
        fill={'#fff'}
      />
      <path
        d={'M29.124 15.017a.495.495 0 0 1 .473.146c.082.09.125.209.142.328.044.317-.095.638-.313.875-.219.237-.509.398-.809.524-.08-.403.04-.806.09-1.204.034-.28.08-.588.417-.668v-.001Z'}
        fill={'#CE5353'}
      />
      <path
        d={'M34.293 30.016s-1.24-5.825-2.425-6.602c-1.185-.776-4.28-1.13-4.28-1.13a10.668 10.668 0 0 1-3.179 0s-3.098.354-4.282 1.131c-1.183.776-2.423 6.602-2.423 6.602l2.987.741-.8 10.786h12.214l-.8-10.786 2.987-.74.001-.002Z'}
        fill={'#0201FB'}
      />
      <path
        d={'M22.463 14.935c.377-.031.693-.297.92-.595.225-.298.39-.638.632-.922.535-.63 1.374-.915 2.17-1.17a3.802 3.802 0 0 0 2.898 1.633c.06-.678-.217-1.336-.49-1.962-.102-.236-.207-.476-.375-.672-.193-.227-.46-.383-.738-.5-1.08-.454-2.362-.342-3.41.178a2.82 2.82 0 0 0-.678.45c-.243.228-.422.513-.573.806-.424.825-.368 1.831-.355 2.754h-.001Z'}
        fill={'#0C0C0B'}
      />
      <path
        d={'M40.026 26.883c-.609 7.34-6.727 13.107-14.185 13.107-7.457 0-13.575-5.767-14.184-13.107H0v17.808h53.316V26.883h-13.29Z'}
        fill={'transparent'}
      />
      <path
        clipRule={'evenodd'}
        d={'m22.289 15.868.15.012.149.013v.007a1.252 1.252 0 0 0-.006.116 3.77 3.77 0 0 0 .01.335c.018.28.073.649.213 1.016.14.366.362.725.714.992.352.266.843.45 1.537.45v.3c-.75 0-1.308-.2-1.718-.51a2.503 2.503 0 0 1-.814-1.125 4.126 4.126 0 0 1-.238-1.567l.002-.028v-.011h.001Z'}
        fill={'#0201FB'}
        fillRule={'evenodd'}
      />
      <path
        d={'M22.414 16.201c.152-.012.26-.216.242-.457-.02-.24-.159-.426-.311-.414-.153.012-.261.217-.242.458.019.24.158.426.31.413ZM25.152 19.306c.371 0 .673-.155.673-.346 0-.192-.302-.347-.673-.347-.372 0-.674.155-.674.347 0 .191.302.346.674.346Z'}
        fill={'#0201FB'}
      />
      <path
        d={'M23.474 26.343a.81.81 0 1 1 .81-.81h-.341a.47.47 0 1 0-.469.47v.34ZM24.068 26.343a.216.216 0 1 0 0-.431.216.216 0 0 0 0 .431Z'}
        fill={'transparent'}
      />
      <path
        clipRule={'evenodd'}
        d={'M22.34 15.55c.057-.7.325-2.05.325-2.05l-.138 2.036-.186.014Z'}
        fill={'#0201FB'}
        fillRule={'evenodd'}
      />
      <path
        clipRule={'evenodd'}
        d={'M44.769 24.49C44.508 14.079 35.857 5.85 25.446 6.113c-10.41.26-18.638 8.912-18.377 19.323l-6.105.153C.618 11.806 11.51.354 25.293.008c13.782-.346 25.235 10.546 25.58 24.329.346 13.782-10.546 25.235-24.328 25.58l-.153-6.104c10.41-.261 18.638-8.912 18.377-19.323Z'}
        fill={'#0201FB'}
        fillRule={'evenodd'}
      />
      <path
        d={'M1.95 45.447a4.67 4.67 0 1 0 9.337-.234 4.67 4.67 0 0 0-9.337.234Z'}
        fill={'#FF0076'}
      />
      <path
        d={'m32.285 30.127-1.094.658 4 1.309 6.629 1.453s2.518 1.246 2.9.353c.383-.894-.951-1.202-.951-1.202s.525.015.496-.296c-.029-.31-1.729-.327-1.729-.327l-8.676-3.681-.224.37a3.91 3.91 0 0 1-1.353 1.363h.002Z'}
        fill={'#CE5353'}
      />
    </svg>

  )
}
