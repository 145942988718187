import isIBAN from 'validator/es/lib/isIBAN'

import type { ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

export function validateIban<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>): ValidationResult {
  return {
    valid: shouldValidate(value)
      ? isIBAN(value)
      : true,
    message: intl.formatMessage({
      id: 'fields.error.iban',
      defaultMessage: 'This is not a valid IBAN!'
    })
  }
}
