import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { CheckLineIcon } from '@oi/react/components/icons'

interface AuthBottomProps {
  variant: 'primary' | 'secondary'
  points: string[]
}

const StyledBottom = styled('div')<Pick<AuthBottomProps, 'variant'>>(({ theme, variant }) => ({
  backgroundColor: theme.palette[variant].main,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(4, 3),
  color: theme.palette.common.white,

  [`& .${listItemIconClasses.root}`]: {
    minWidth: 'fit-content',
    marginRight: theme.spacing(1),

    '& svg': {
      fill: theme.palette.common.white
    }
  }
}))

export default function AuthBottom({ variant = 'primary', points }: AuthBottomProps) {

  return (
    <StyledBottom variant={variant}>
      <List>
        {points.map((item) => (
          <ListItem
            key={item}
            disablePadding>
            <ListItemIcon>
              <CheckLineIcon />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color={'inherit'}
                  variant={'h6'}>
                  {item}
                </Typography>
              )} />
          </ListItem>
        ))}
      </List>
    </StyledBottom>
  )
}
