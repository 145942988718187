import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/max'

import type { FieldValidations, ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

export function parsePhoneNumber(value: string | unknown, countryCode: string) {
  if (typeof value !== 'string' || !shouldValidate(value)) {
    return undefined
  }

  return parsePhoneNumberFromString(value, countryCode as CountryCode)
}

export function validateMobileNumber<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: FieldValidations['mobile']): ValidationResult {
  const countryCode = options && formValues[options.countryCodeField]
  const phoneNum = parsePhoneNumber(value, countryCode)

  return {
    valid: shouldValidate(value)
      ? Boolean(phoneNum?.isValid() && phoneNum?.getType() === 'MOBILE')
      : true,
    message: intl.formatMessage({
      id: 'fields.error.mobile-number',
      defaultMessage: 'This is not a valid mobile number!'
    })
  }
}
