/* eslint-disable simple-import-sort/imports */
import Base from './field.base.component'

/**
 * Base fields
 */
import Text from './field.text.component'
import Checkbox from './field.checkbox.component'
import AutoComplete from './field.auto-complete.component'
import Date from './field.date.component'
import Money from './field.money.component'
import Country from './field.country.component'
import Group from './field.group.component'
import Collection from './field.collection.component'
import Radio from './field.radio.component'

export const Field = {
  Base,
  Group,
  Collection,

  Text,
  Date,
  Money,
  Checkbox,
  Radio,

  Country,

  AutoComplete
}

export type { FieldAutoCompleteProps, FieldAutoCompleteRef } from './field.auto-complete.component'
