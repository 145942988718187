import React from 'react'
import { FormattedMessage } from 'react-intl'
import ButtonBase from '@mui/material/ButtonBase'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { CloseLineIcon, TutorialIcon } from '@oi/react/components/icons'
import { useSpotlightAPI } from '@oi/react/components/spotlight'

interface TutorialTooltipContainerProps {
  header: React.ReactNode
}

const SkipButton = styled(ButtonBase)({
  alignItems: 'center'
})

export default function TutorialTooltipContainer({ header, children }: React.PropsWithChildren<TutorialTooltipContainerProps>) {
  const { close } = useSpotlightAPI()

  return (
    <>
      <Stack
        alignItems={'start'}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Stack
          alignItems={'center'}
          direction={'row'}
          spacing={2}>
          <TutorialIcon large />
          <Typography
            fontWeight={'bolder'}
            variant={'body1'}>
            {header}
          </Typography>
        </Stack>
        <SkipButton onClick={close}>
          <Typography variant={'caption'}>
            <FormattedMessage
              defaultMessage={'Skip'}
              id={'tutorial.skip-btn'}/>
          </Typography>
          <CloseLineIcon/>
        </SkipButton>
      </Stack>
      <Stack>
        {children}
      </Stack>
    </>
  )
}

