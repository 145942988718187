import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { useIntl } from 'react-intl'
import MaterialAutocomplete from '@mui/material/Autocomplete'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import OutlinedInput from '@mui/material/OutlinedInput'
import { SearchLineIcon } from '@oi/react/components/icons'

import type { BaseField } from '../fields.interface'
import type { FieldTextProps } from './field.text.component'
import type { FilterOptionsState } from '@mui/base/useAutocomplete'
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'

import { useFieldName, useFieldsController } from '../fields.hooks'
import FieldBase from './field.base.component'
import { fieldClasses } from './field.classes'

export interface FieldAutoCompleteRef {
  clear: () => void
  setOptions: (options: unknown[]) => void
}

export interface FieldAutoCompleteProps<
  Item,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<FieldTextProps<TFieldValues, TName>, 'startAdornment'>, Omit<BaseField, 'name' | 'defaultValue'> {
  labelProp: keyof Item | string
  valueProp: keyof Item | string
  valuePropRequired?: boolean
  dataSource?: Item[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderOption?: (props: ListItemProps, option: Item | any) => React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel?: (option: Item | string | any) => string
  // getOptionDisabled?: (option: Item) => boolean
  freeSolo?: boolean

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (newValue: Item | any) => void
  disableStartAdornment?: boolean
}

function FieldAutoComplete<
  Item,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: FieldAutoCompleteProps<Item, TFieldValues, TName>, ref: React.ForwardedRef<FieldAutoCompleteRef>) {
  const {
    name,
    label,
    labelVariant,
    required,
    defaultValue,
    disabled,
    hidden,
    placeholder,

    dataSource,
    renderOption: renderOptionProp,
    getOptionLabel: getOptionLabelProp,
    valueProp,
    labelProp,
    disableStartAdornment = false,
    freeSolo = false,

    onChange,
    onInputChange,

    ...outlinedInputProps
  } = props

  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
    disabled
  })

  const [options, setOptions] = React.useState<Item[]>(dataSource || [])
  // const [inputValue, setInputValue] = React.useState('')
  // const [loading, setLoading] = React.useState(false)
  const intl = useIntl()

  React.useEffect(() => {
    if (dataSource) {
      setOptions(dataSource)
    }
  }, [dataSource])

  React.useImperativeHandle(ref, () => ({
    clear: () => {
      // setInputValue('')
      field.onChange('')
      setOptions([])
    },
    setOptions: (options: unknown[]) => {
      setOptions(options as Item[])
    }
  }))

  const getItemValue = React.useCallback((item: Item | string) => {
    if (typeof item === 'string' || !item) {
      return item
    }

    return item[valueProp as keyof Item]
  }, [valueProp])

  const handleOnChange = React.useCallback((event: React.SyntheticEvent, items: string | Item | (string | Item)[] | null) => {
    field.onChange(
      // eslint-disable-next-line no-nested-ternary
      Array.isArray(items)
        ? items?.map(getItemValue) ?? []
        : getItemValue(items as Item)
    )

    onChange?.(items as never)
  }, [field, getItemValue, onChange])

  const handleIsOptionEqualToValue = React.useCallback((option: Item, value: string | Item) => {
    if (typeof value === 'string') {
      return option[valueProp as keyof Item] === value
    }

    return option[valueProp as keyof Item] === value[valueProp as keyof Item]
  }, [valueProp])

  const getOptionLabel = React.useCallback((option: string | Item): string => {
    if (getOptionLabelProp) {
      return getOptionLabelProp(option as Item)
    }

    const originalOption = option
    if (typeof option !== 'object') {
      option = options.find((item) => item[valueProp as keyof Item] === option) as Item
    }

    // Option not found, return empty string
    if (!option) {
      return (freeSolo && originalOption ? originalOption : '') as string
    }

    return (typeof option === 'string' ? option : option[labelProp as keyof Item]) as string
  }, [freeSolo, getOptionLabelProp, labelProp, options, valueProp])

  const handleInputChange = React.useCallback((event: React.SyntheticEvent, newInputValue: string) => {
    // setInputValue(newInputValue)

    onInputChange?.(newInputValue)
  }, [onInputChange])

  const renderOption = React.useCallback((props: ListItemProps, option: Item) => {
    if (renderOptionProp) {
      return renderOptionProp(props, option)
    }

    const label = getOptionLabel(option) as string

    return (
      <ListItem
        {...props}
        key={props.id || props.key}
        data-test-id={`${props.id || props.key}`}>
        <ListItemText primary={label} />
      </ListItem>
    )
  }, [])

  const handleServerFilter = React.useCallback((options: Item[], state: FilterOptionsState<Item>): Item[] => {
    return options
  }, [])

  return (
    <FieldBase
      error={fieldState.error}
      hidden={hidden}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      required={required}>
      <MaterialAutocomplete
        className={fieldClasses.field}
        disabled={disabled || controller.disabled}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        id={name}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        options={options}
        popupIcon={null}
        renderOption={renderOption}
        value={field.value}
        filterOptions={
          !dataSource
            ? handleServerFilter
            : undefined
        }
        loadingText={intl.formatMessage({
          id: 'system.searching',
          defaultMessage: 'Searching...'
        })}
        noOptionsText={intl.formatMessage({
          id: 'system.no-results',
          defaultMessage: 'No items found!'
        })}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <OutlinedInput
            // autoFocus={autoFocus}
            error={!!fieldState.error}
            placeholder={field.value?.length > 0 ? '' : placeholder}
            fullWidth
            {...outlinedInputProps}
            {...params.InputProps}
            startAdornment={!disableStartAdornment && <SearchLineIcon fontSize={'small'} />}
            inputProps={{
              ...params.inputProps,
              className: fieldClasses.input,
              autoComplete: 'do-not-autofill',
              'data-test-id': `${name}`
            }}
          />
        )}
        openOnFocus />
    </FieldBase>
  )
}

export default React.memo(React.forwardRef(FieldAutoComplete))
