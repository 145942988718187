import React from 'react'
import { IntlProvider } from 'react-intl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import type { GetMessages, Language,Locale } from './language.interfaces'

import { LanguageContext } from './language.hooks'
import { getInitialLocale, storeNewLocale } from './language.utils'

export interface LanguageProviderProps {
  supportedLocales: Locale[]
  getMessages: GetMessages
  loadMessages: (language: Language) => Promise<void>
}

export default function LanguageProvider({
  supportedLocales,
  loadMessages,
  getMessages,
  children
}: React.PropsWithChildren<LanguageProviderProps>) {
  const [locale, setLocale] = React.useState(getInitialLocale())
  const { messages, dateMessages } = getMessages(locale as Language)

  React.useEffect(() => {
    const htmlAttribute = document.getElementsByTagName('html')?.[0]

    htmlAttribute?.setAttribute('lang', locale)
  }, [locale])

  const handleSwitchLanguage = React.useCallback(async (languageCode: Language) => {
    // Prevents the full-screen loader from becoming visible
    await loadMessages(languageCode)

    storeNewLocale(languageCode)
    setLocale(languageCode)
  }, [])

  const apiValue = React.useMemo(() => ({
    supportedLocales,
    switchLanguage: handleSwitchLanguage,
    defaultLocale: 'en-GB',
    locale: locale === 'en' ? 'en-GB' : locale,
    // Make sure the first letter is an uppercase so that it matches the Language enum
    activeLocale: locale.replace(/./, (locale) => locale.toUpperCase())
  }), [handleSwitchLanguage, supportedLocales, locale])

  return (
    <LanguageContext.Provider value={apiValue}>
      <LocalizationProvider
        adapterLocale={dateMessages}
        dateAdapter={AdapterDateFns}>
        <IntlProvider
          defaultLocale={apiValue.defaultLocale}
          locale={apiValue.locale}
          messages={messages}>
          {children}
        </IntlProvider>
      </LocalizationProvider>
    </LanguageContext.Provider>
  )
}
