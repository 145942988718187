import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  SpotlightItem, useSpotlightAPI
} from '@oi/react/components/spotlight'

export interface TutorialProps {
  spotlightKey: string
  component: React.ReactNode
  placement: TooltipProps['placement']
  hideItemFrame?: boolean
  children: React.ReactElement | Array<React.ReactElement>
}

const StyledStepCount = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`
}))

export default function TutorialTooltip({
  spotlightKey, component, placement, hideItemFrame = false,
  children
}: TutorialProps) {

  const { nextStep, hasMore, steps, step } = useSpotlightAPI()

  return (
    <SpotlightItem
      hideItemFrame={hideItemFrame}
      placement={placement}
      spotlightKey={spotlightKey}
      title={(
        <Stack
          spacing={2}>
          {component}
          <Divider variant={'middle'} />
          <Stack
            alignItems={'center'}
            direction={'row'}
          >
            <Stack
              alignItems={'center'}
              flexGrow={1}>
              <StyledStepCount
                fontWeight={'bolder'}
                variant={'body1'}>
                {step}/{steps}
              </StyledStepCount>
            </Stack>
            <Button
              onClick={nextStep}
              variant={'contained'}>
              {hasMore && (
                <FormattedMessage
                  defaultMessage={'Next'}
                  id={'tutorial.next-btn'} />
              )}
              {!hasMore && (
                <FormattedMessage
                  defaultMessage={'Got it'}
                  id={'tutorial.got-it-btn'} />
              )}
            </Button>
          </Stack>

        </Stack>
      )}>
      {children}
    </SpotlightItem>
  )
}
