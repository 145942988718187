import React from 'react'
import Stack from '@mui/material/Stack'

import type { FieldsRef } from './fields.interface'

import Controller, { FieldsControllerProps } from './fields.controller'

export interface FieldsProps<Fields extends object> extends FieldsControllerProps<Fields> {
  className?: string
}

function Fields<Fields extends object>({
  children,
  className,
  ...rest
}: React.PropsWithChildren<FieldsProps<Fields>>, ref: React.ForwardedRef<FieldsRef>) {
  return (
    <Stack
      className={className}
      component={'form'}
      gap={1.5}
      style={{ position: 'relative', width: '100%' }}>
      <Controller
        ref={ref}
        {...rest}>
        {children}
      </Controller>
    </Stack>
  )
}

export default React.forwardRef(Fields)
