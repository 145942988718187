import React from 'react'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import MuiSkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton'

import type { Theme } from '@mui/material/styles'
import type { SxProps } from '@mui/system'

export interface SkeletonProps extends MuiSkeletonProps {
  enabled?: boolean
  inheritSize?: boolean
  inheritSx?: boolean
  boxSx?: SxProps<Theme>
  childrenType?: 'default' | 'typography'
}

export const skeletonClasses = {
  root: 'StraetusSkeleton--root'
}

export default function Skeleton({
  enabled = false,
  width,
  height,
  inheritSize,
  inheritSx,
  childrenType = 'default',
  sx,
  boxSx,
  children,
  ...rest
}: SkeletonProps) {
  if (enabled) {
    return (
      <MuiSkeleton
        height={height}
        sx={sx}
        width={width}
        {...rest}>
        {childrenType === 'default' && children}
      </MuiSkeleton>
    )
  }

  if (!children) {
    return null
  }

  // TODO:: Why not always  display: 'contents' ??

  return (
    <Fade in>
      <Box
        className={skeletonClasses.root}
        component={'span'}
        height={inheritSize ? height : undefined}
        sx={inheritSx ? sx : boxSx}
        width={inheritSize ? width : undefined}>
        {children}
      </Box>
    </Fade>
  )
}
