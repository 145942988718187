import { useQuery } from '@tanstack/react-query'

import type { Me } from './open-api'
import type { Company, CompanyApiCompanyUpdateRequest, LoginDto } from './open-api'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/build/modern'

import { companyApi, oauthApi } from './api.constants'
import { unwrapResponse, useWrapMethod } from './api.internal'
import { queryClient } from './api.methods'

function getMeQueryOptions(): UndefinedInitialDataOptions<Me> {
  return {
    queryKey: ['me'],
    queryFn: () => unwrapResponse(oauthApi.me()),
    // Make sure to not re-fetch it everytime the hook is used
    refetchOnMount: false
  }
}

export function getMe(): Promise<Me> {
  return queryClient.ensureQueryData(getMeQueryOptions())
}

export function useActiveUser(): Me {
  const { data } = useQuery(getMeQueryOptions())

  return data as Me
}

export function useUpdateCompanyMutation() {
  return useWrapMethod<
    CompanyApiCompanyUpdateRequest,
    Company
  >(
    (params: CompanyApiCompanyUpdateRequest) => companyApi.companyUpdate(params),
    () => ['me'],
    // We are going to update a different cache key
    <Me>(existing: Me, data: Company) => ({
      ...existing,
      company: data
    } as Me)
  )
}

export function useLoginMutation() {
  return useWrapMethod<
    LoginDto,
    void
  >((loginDto) => oauthApi.login({ loginDto }))
}

export function useLogoutMutation() {
  return useWrapMethod(async () => {
    const response = await oauthApi.logout()
    queryClient.clear()

    return response
  })
}
