import React from 'react'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

interface HeaderMenuButtonProps {
  isOpen?: boolean
  onClick: () => void
}

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

const StyledMenuButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
  '& > div': {
    margin: 6,
    width: 22,
    height: 2.5,
    background: theme.palette.secondary.dark,
    borderRadius: 1.5,
    transition: 'transform 0.2s'
  },
  ...(isOpen && {
    '& > div:first-child': {
      background: theme.palette.common.white,
      transform: 'translateY(8.5px) rotate(45deg)'
    },
    '& > div:nth-child(2)': {
      opacity: 0
    },
    '& > div:last-child': {
      background: theme.palette.common.white,
      transform: 'translateY(-8.5px) rotate(-45deg)'
    }
  })
}))

export default function HeaderMenuButton({ isOpen, onClick }: HeaderMenuButtonProps) {
  return (
    <StyledIconButton
      onClick={onClick}>
      <StyledMenuButton
        isOpen={isOpen}>
        <div />
        <div />
        <div />
      </StyledMenuButton>
    </StyledIconButton>
  )
}
