import isISO31661Alpha2 from 'validator/lib/isISO31661Alpha2'
import isPostalCode from 'validator/lib/isPostalCode'

import type { FieldValidations, ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

export function validateZipcode<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: FieldValidations['postalCode']): ValidationResult {
  const countryCode = options && formValues[options.countryCodeField]

  return {
    valid: shouldValidate(value)
      ? isISO31661Alpha2(countryCode) && isPostalCode(value, countryCode)
      : true,
    message: intl.formatMessage({
      id: 'fields.error.postal-code',
      defaultMessage: 'This is not a valid zipcode!'
    })
  }
}
