import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { formHelperTextClasses } from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'
import { CheckboxBlankLineIcon, CheckboxFillIcon, CheckboxIndeterminateLineIcon } from '@oi/react/components/icons'
import clsx from 'clsx'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase, { FieldBaseProps, StyledInputLabel } from './field.base.component'
import { fieldClasses } from './field.classes'

export interface FieldCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<CheckboxProps, 'required' | 'disabled' | 'color' | 'size'>, BaseField {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>

  checkedValue?: boolean | string
  uncheckedValue?: boolean | string
}

const StyledFieldBase = styled(FieldBase, {
  shouldForwardProp: (prop) => prop !== 'size'
})<FieldBaseProps & { size?: 'small' | 'medium' | 'large' }>(({ size, theme }) => ({
  [`& .${formHelperTextClasses.root}`]: {
    marginTop: 6,
    marginLeft: size === 'small' ? 31 : 36,

    [`&:not(.${formHelperTextClasses.error})`]: {
      marginTop: 0,
      ...theme.typography.caption
    }
  }
}))

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  padding: 3
}))

export default function FieldCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  hint,

  checkedValue = true,
  uncheckedValue = false,

  size,
  ...checkboxProps
}: FieldCheckboxProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange({
      ...event,
      target: {
        ...event.target,
        value: checked ? checkedValue : uncheckedValue
      }
    })
  }, [checkedValue, field, uncheckedValue])

  return (
    <StyledFieldBase
      error={fieldState.error}
      hidden={hidden}
      hint={hint}
      name={field.name}
      required={required}
      size={size}>
      <FormControlLabel
        control={
          <StyledCheckBox
            {...checkboxProps}
            ref={field.ref}
            checked={field.value === checkedValue}
            checkedIcon={<CheckboxFillIcon />}
            disabled={field.disabled || controller.disabled}
            icon={<CheckboxBlankLineIcon />}
            indeterminateIcon={<CheckboxIndeterminateLineIcon />}
            name={field.name}
            onBlur={field.onBlur}
            onChange={handleOnChange}
            onKeyDown={controller.onKeyDown}
            size={size}
          />
        }
        label={(
          <StyledInputLabel
            className={clsx(fieldClasses.inputLabel, fieldClasses.inputLabelThin)}
            required={false}
            sx={{
              // Makes sure the whole label is clickable to "check" this checkbox
              pointerEvents: 'none'
            }}>
            {label}

            {required && (
              <span className={fieldClasses.requiredAstrix}>
                &nbsp;*
              </span>
            )}
          </StyledInputLabel>
        )}
      />
    </StyledFieldBase>
  )
}
