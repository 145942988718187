import React from 'react'
import { FormattedMessage } from 'react-intl'

import { RegisterProps } from '../register.component'

export function useRegisterSubtitle(type: RegisterProps['type']) {

  let subtitle = (
    <FormattedMessage
      defaultMessage={'Register for free with your own collection agency.'}
      id={'routes.register.subtitle.default'} />
  )

  if (type === 'incasso') {
    subtitle = (
      <FormattedMessage
        defaultMessage={'Register for free and start with incasso.'}
        id={'routes.register.subtitle.incasso'} />
    )
  }

  if (type === 'credit-check') {
    subtitle = (
      <FormattedMessage
        defaultMessage={'Register for free and request a credit report.'}
        id={'routes.register.subtitle.credit'} />
    )
  }

  return subtitle

}
