import { addMessagesToCache, getMessagesFromCache, GetMessagesResponse, Language } from '@oi/react/modules/language'

export function getMessages(language: Language): GetMessagesResponse {
  const cache = getMessagesFromCache(language)

  if (cache) {
    return cache
  }

  // Suspense is based on ErrorBoundary
  // throwing a promise will cause <SomeLoadingComponent /> to render until the promise resolves
  throw loadMessages(language)
}

export function fetchTranslations(languageCode: string): Promise<GetMessagesResponse['messages']> {
  return import('../translations/en.json') as never
  // return fetch(`https://cdn.simplelocalize.io/7d6ef1ff99e54d76a241e79ecd8e2d7e/_latest/${languageCode}`)
  //   .then((response) => response.json())
  //   .catch(() => import('../translations/en.json'))
}

export async function loadMessages(language: Language): Promise<void> {
  let dateMessages

  const locale = language.toLowerCase()

  switch (locale) {
    case 'nl':
      dateMessages = (await import('date-fns/locale/nl')).nl
      break

    default:
      dateMessages = (await import('date-fns/locale/en-GB')).enGB
  }

  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages)
}
