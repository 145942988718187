import { AbilityBuilder, AbilityClass, createAliasResolver, PureAbility } from '@casl/ability'
import { Action, Claim, OauthTokenType, ScopeAction } from '@oi/constants/auth'

export type AppAbility = PureAbility<[Action, Claim]>

// app/models/ability.rb

export function createCaslAbilityBuilder(owner: boolean, admin: boolean, tokenType: OauthTokenType) {
  const abilityBuilder = new AbilityBuilder<AppAbility>(PureAbility as AbilityClass<AppAbility>)

  // If the user has the admin can do everything
  // if (admin) {
  //   read-write access to everything
  // abilityBuilder.can(Action.Manage, Claim.All)
  // }

  // Every has access to the following things
  abilityBuilder.can(Action.Manage, Claim.CreditCase)
  abilityBuilder.can(Action.Manage, Claim.CreditCheck)

  if (owner) {
    // Add rules
  }

  if (admin) {
    // Add rules
  }

  if (tokenType === OauthTokenType.ThirdParty) {
    // Add rules
  }

  return abilityBuilder
    .build({
      resolveAction: createAliasResolver({
        [ScopeAction.Write]: [Action.Create, Action.Update, Action.Read]
      })
    })
}
