import { useQuery } from '@tanstack/react-query'

import { creditChecksApi } from './api.constants'
import { DEFAULT_LIST_OPTIONS, unwrapResponse } from './api.internal'
import { ListOptions, queryClient } from './api.methods'

export function listCreditChecks(options: ListOptions = DEFAULT_LIST_OPTIONS) {
  return queryClient.ensureQueryData({
    queryKey: ['credit-checks', options.offset, options.limit, options.query],
    queryFn: () => unwrapResponse(creditChecksApi.creditChecksQueryMany(options))
  })
}

export function getCreditCheck(id: string) {
  return queryClient.ensureQueryData({
    queryKey: ['credit-check', id],
    queryFn: () => unwrapResponse(creditChecksApi.creditChecksFindById({ id }))
  })
}

export function searchCreditChecks(options: ListOptions = DEFAULT_LIST_OPTIONS) {
  return queryClient.ensureQueryData({
    queryKey: ['credit-checks', 'search', options.offset, options.limit, options.query],
    queryFn: () => unwrapResponse(creditChecksApi.creditCheckSearch({
      query: options.query || ''
    }))
  })
}

export function useCreditCheckSearch(query: string) {
  return useQuery({
    queryKey: ['credit-check', 'search', query],
    queryFn: () => unwrapResponse(creditChecksApi.creditCheckSearch({ query }))
  })
}
