import React from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import { iconButtonClasses } from '@mui/material/IconButton'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { CalendarLineIcon } from '@oi/react/components/icons'
import { parseISO } from 'date-fns/parseISO'

import type { BaseField } from '../fields.interface'

import { useFieldName, useFieldsController } from '../fields.hooks'
import { useFieldValidate } from '../validations/field-validate.hook'
import FieldBase from './field.base.component'

export interface FieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<OutlinedInputProps, 'required' | 'type' | 'disabled' | 'color' | 'placeholder' | 'startAdornment' | 'endAdornment'>, BaseField {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>

  disableFuture?: boolean
}

const StyledFieldBase = styled(FieldBase)(({ theme }) => ({
  [`& .${iconButtonClasses.root}`]: {
    margin: theme.spacing(0, 0, 0, -2.5),

    '& svg': {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      fontSize: 20,
      opacity: 0
    }
  },

  '&:hover': {
    '& svg': {
      opacity: 1
    }
  }
}))

export default function FieldDate<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  labelVariant,
  required,
  defaultValue,
  disabled,
  hidden,
  validations,
  disableFuture = true,
  ...outlinedInputProps
}: FieldTextProps<TFieldValues, TName>) {
  const controller = useFieldsController()
  const fieldName = useFieldName<TName>(name)
  const validate = useFieldValidate(validations)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: {
      required,
      validate
    },
    defaultValue,
    disabled
  })

  const transformOutput = React.useCallback((onChange: (value: string | Date | null) => void) => (value: string | Date | null) => {
    if (value && value instanceof Date) {
      // Set the time at 12, so we always get the correct date for almost all countries
      // this way we only need to implement timezone support in the backend when difference is bigger than +-12
      value.setHours(12, 0, 0, 0)
    }

    onChange(value)
  }, [])

  const transformInput = React.useCallback((value: string | Date | null): Date => {
    if (value && typeof value === 'string') {
      return parseISO(value)
    }

    return value as Date
  }, [])

  return (
    <StyledFieldBase
      error={fieldState.error}
      hidden={hidden}
      label={label}
      labelVariant={labelVariant}
      name={field.name}
      required={required}>
      <DatePicker
        ref={field.ref}
        disabled={field.disabled || controller.disabled}
        disableFuture={disableFuture}
        onChange={transformOutput(field.onChange)}
        value={transformInput(field.value || null) as never}
        slots={{
          openPickerIcon: CalendarLineIcon
        }}
      />
    </StyledFieldBase>
  )
}
