import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'

import type { FieldValidations, ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { shouldValidate } from './validate.hooks'

function getDate(value: Date | string): Date {
  if (typeof value === 'string') {
    return parseISO(value)
  }

  return value
}

export function validateNotBefore<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: FieldValidations['notBefore']): ValidationResult {
  const notBeforeFieldValue = getDate(options && formValues[options.field])

  return {
    valid: shouldValidate(value)
      ? isAfter(getDate(value), notBeforeFieldValue)
      : true,
    message: intl.formatMessage({
      id: 'fields.error.not-before',
      defaultMessage: 'Should not be before {fieldName}!'
    }, {
      fieldName: options && options.fieldLabel
    })
  }
}
