import { get } from 'react-hook-form'
import { useIntl } from 'react-intl'

import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { Validate, ValidateResult } from 'react-hook-form/dist/types/validator'
import type { IntlShape } from 'react-intl'

import { useFieldsController } from '../fields.hooks'
import { validateEmail } from './field-validate.email'
import { validateIban } from './field-validate.iban'
import { validateMobileNumber } from './field-validate.mobile'
import { validateNotBefore } from './field-validate.not-before'
import { validatePhoneNumber } from './field-validate.phone'
import { validateSameAs } from './field-validate.same-as'
import { validateScore } from './field-validate.score'
import { validateZipcode } from './field-validate.zipcode'

export interface FieldValidations {
  iban?: boolean
  email?: boolean
  mobile?: {
    countryCodeField: string
  }
  phone?: {
    countryCodeField: string
  }
  postalCode?: {
    countryCodeField: string
  }
  score?: boolean
  sameAs?: {
    field: string
    fieldLabel: string
  }
  notBefore?: {
    field: string
    fieldLabel: string
  }
}

export type AvailableFieldValidations = keyof FieldValidations

export interface ValidationResult {
  valid: boolean
  message: string
}

type ValidateFunctionOptions<ValidationOptions> =
  ValidationOptions extends boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ? any
    : ValidationOptions

type ValidateFunction<
  Validation extends AvailableFieldValidations = AvailableFieldValidations,
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = (intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: ValidateFunctionOptions<FieldValidations[Validation]>) => ValidationResult

type ValidationMap<Validation extends AvailableFieldValidations> = Record<Validation, ValidateFunction<Validation>>

const validationMap: ValidationMap<AvailableFieldValidations> = {
  iban: validateIban,
  email: validateEmail,
  phone: validatePhoneNumber,
  mobile: validateMobileNumber,
  postalCode: validateZipcode,
  score: validateScore,
  sameAs: validateSameAs,
  notBefore: validateNotBefore
}

export function useFieldValidate<
  Validation extends AvailableFieldValidations = AvailableFieldValidations,
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(validations?: FieldValidations): Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues> | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>> {
  const controller = useFieldsController()
  const intl = useIntl()

  return async (value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues): Promise<ValidateResult> => {
    if (!validations) {
      return true
    }

    const validationTypes = Object.keys(validations) as AvailableFieldValidations[]

    for (const validationType of validationTypes) {
      const options = validations[validationType] as ValidateFunctionOptions<FieldValidations[Validation]>

      const { valid, message } = validationMap[validationType](
        intl,
        value,
        controller.namePrefix ? get(formValues, controller.namePrefix) : formValues,
        options
      )

      if (!valid) {
        return message
      }
    }

    return true
  }
}
