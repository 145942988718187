import React from 'react'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import HeroPng from '@oi/react/assets/hero.png'
import Footer from '@oi/react/components/footer'

import AuthBottom from '../../auth-bottom.component'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  overflow: 'hidden',

  flexDirection: 'column',
  height: '100%',

  '& > img': {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    height: `calc(100vh - ${theme.spacing(30)})`,
    width: '100%',
    objectFit: 'cover',
    zIndex: 1
  }
}))

export default function LoginInfoSide() {
  const intl = useIntl()

  const points = React.useMemo(() => ([
    intl.formatMessage({
      id: 'routes.login.side-info.point-1',
      defaultMessage: 'You can sign up without any obligation'
    }),
    intl.formatMessage({
      id: 'routes.login.side-info.point-2',
      defaultMessage: 'Fixed prices per process'
    }),
    intl.formatMessage({
      id: 'routes.login.side-info.point-3',
      defaultMessage: 'You can keep the collection costs'
    })
  ]), [intl])

  return (
    <Root>
      <img
        alt={'Hero'}
        src={HeroPng} />

      <Box flexGrow={1} />

      <AuthBottom
        points={points}
        variant={'primary'} />

      <Footer />
    </Root>
  )
}
