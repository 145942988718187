import React from 'react'
import { useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import { apiGlobalErrorMessage } from '@oi/react/modules/api'

import type { ApiError } from '@oi/react/modules/api'

export interface FieldErrorProps {
  error?: ApiError | string
  errorMessages?: {
    [key: string]: {
      id: string
      defaultMessage: string
    }
  }
}

export default function FieldError({
  error,
  errorMessages = {}
}: FieldErrorProps) {
  const [globalError, setGlobalError] = React.useState<string | null>(null)
  const intl = useIntl()

  React.useEffect(() => {
    if (error) {
      let errorMessage
      const errorCode = typeof error === 'string'
        ? error
        : error.code

      if (errorCode in errorMessages) {
        errorMessage = intl.formatMessage(errorMessages[errorCode])

        // } else if (errorCode === 'api.validation' && typeof error !== 'string') {
        // TODO::
        // const fieldErrors = getGraphqlValidationError(error.graphQLErrors)
        //
        // Object.keys(fieldErrors).map((fieldName) => {
        //   const correctFieldName = Object.keys(controller.current).find((fieldKey) => (
        //     controller.current[fieldKey].field.name === fieldName
        //     || controller.current[fieldKey].field.stateProp === fieldName
        //   ))
        //
        //   if (correctFieldName) {
        //     let apiErrorMessage = fieldErrors[fieldName]
        //
        //     if (apiErrorMessage in errorMessages) {
        //       apiErrorMessage = intl.formatMessage(errorMessages[apiErrorMessage])
        //     }
        //
        //     controller.current[correctFieldName].setErrorMessage(apiErrorMessage)
        //   }
        // })

      } else {
        console.warn(`Following message is missing "${errorCode}" showing global as fallback`)
        errorMessage = intl.formatMessage(apiGlobalErrorMessage)
      }

      if (errorMessage) {
        setGlobalError(errorMessage)
      }
    }
  }, [error, errorMessages, intl])

  if (!globalError) {
    return null
  }

  return (
    <Typography
      color={'error'}
      variant={'subtitle2'}>
      {globalError}
    </Typography>
  )
}
