import type { FieldValidations, ValidationResult } from './field-validate.hook'
import type { FieldPath, FieldValues } from 'react-hook-form'
import type { FieldPathValue } from 'react-hook-form/dist/types/path'
import type { IntlShape } from 'react-intl'

import { parsePhoneNumber } from './field-validate.mobile'
import { shouldValidate } from './validate.hooks'

export function validatePhoneNumber<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(intl: IntlShape, value: FieldPathValue<TFieldValues, TFieldName>, formValues: TFieldValues, options: FieldValidations['phone']): ValidationResult {
  const countryCode = options && formValues[options.countryCodeField]
  const phoneNum = parsePhoneNumber(value, countryCode)

  return {
    valid: shouldValidate(value)
      ? !!phoneNum?.isValid()
      : true,
    message: intl.formatMessage({
      id: 'fields.error.phone-number',
      defaultMessage: 'This is not a valid phone number!'
    })
  }
}
