export enum Language {
  En = 'EN'
}

export interface GetMessagesResponse {
  messages: {
    [key: string]: string
  }
  dateMessages?: string | object
}

export type GetMessages = (language: Language) => GetMessagesResponse

export interface Locale {
  code: Language
  language: string
}
