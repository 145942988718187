import React from 'react'

export function useTooltipOnEllipsis(correction = 0) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const handleMouseOver = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const el = event.currentTarget
    if (el.scrollWidth > (el.clientWidth + correction)) {
      setTooltipOpen(true)
    }
  }, [setTooltipOpen, correction])

  const handleMouseOut = React.useCallback(() => {
    setTooltipOpen(false)
  }, [setTooltipOpen])

  return {
    tooltipOpen,
    handleMouseOver,
    handleMouseOut
  }
}
