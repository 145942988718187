import React from 'react'
import { styled } from '@mui/material/styles'

interface ChevronRightIconProps {
  className?: string
}

function ChevronRightIcon({ className }: ChevronRightIconProps) {
  return (
    <svg
      className={className}
      height={40}
      viewBox={'0 0 33 66'}
      width={20}
    >
      <path
        d={'m1 1 29.681 32L1 65'}
        stroke={'#fff'}
        strokeWidth={2} />
    </svg>
  )
}

export default styled(ChevronRightIcon)({ fill: 'none' })
