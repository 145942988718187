import React from 'react'
import ReactDOM from 'react-dom/client'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import Spotlight from '@oi/react/components/spotlight'
import { queryClient } from '@oi/react/modules/api'
import LanguageProvider, { Locale } from '@oi/react/modules/language'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { getMessages, loadMessages } from './modules/languages.utils'
import { themeOptions } from './modules/theme'
import AppRoutes from './routes/routes.component'
import supportedLocales from './translations/locales.json'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Cache for the ltr version of the styles
const cacheLtr = createCache({
  key: 'css',
  prepend: true
})
cacheLtr.compat = true

const theme = createTheme(themeOptions)

root.render(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <CacheProvider value={cacheLtr}>
        <CssBaseline />

        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools
            buttonPosition={'bottom-right'}
            initialIsOpen={false} />

          <LanguageProvider
            getMessages={getMessages}
            loadMessages={loadMessages}
            supportedLocales={supportedLocales as Locale[]}>
            <Spotlight>
              <AppRoutes />
            </Spotlight>
          </LanguageProvider>
        </QueryClientProvider>
      </CacheProvider>
    </MuiThemeProvider>
  </React.StrictMode>
)
