import { IntlShape, useIntl } from 'react-intl'

import { RegisterProps } from '../register.component'

function defaultRegistrationPoints(intl: IntlShape) {
  return [
    intl.formatMessage({
      id: 'routes.register.side-info.default.point-1',
      defaultMessage: 'You can sign up without any obligation'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.default.point-2',
      defaultMessage: 'Fixed prices per process'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.default.point-3',
      defaultMessage: 'You can keep the collection costs'
    })
  ]
}

function incassoRegistrationPoints(intl: IntlShape) {
  return [
    intl.formatMessage({
      id: 'routes.register.side-info.incasso.point-1',
      defaultMessage: 'You can register without obligation'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.incasso.point-2',
      defaultMessage: 'Fixed prices per process'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.incasso.point-3',
      defaultMessage: 'You can keep the collection costs yourself'
    })
  ]
}

function creditRegistrationPoints(intl: IntlShape) {
  return [
    intl.formatMessage({
      id: 'routes.register.side-info.credit-check.point-1',
      defaultMessage: 'You can register without obligation.'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.credit-check.point-2',
      defaultMessage: 'We do nothing with your data without your permission'
    }),
    intl.formatMessage({
      id: 'routes.register.side-info.credit-check.point-3',
      defaultMessage: 'You reduce the risk of default'
    })
  ]
}

export function useRegistrationBottomPoints(type: RegisterProps['type']): string[] {
  const intl = useIntl()

  if (type === 'default') {
    return defaultRegistrationPoints(intl)
  }

  if (type === 'incasso') {
    return incassoRegistrationPoints(intl)
  }

  if (type === 'credit-check') {
    return creditRegistrationPoints(intl)
  }

  return []
}
