import { QueryClient } from '@tanstack/react-query'

export interface ListOptions {
  limit?: number
  offset?: number
  query?: string
}

export interface ApiError {
  message: string
  code: string
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Make sure to not re-fetch it everytime the hook is used
      refetchOnMount: false
    }
  }
})

export function refetchQueries(queryKey: string[]): Promise<void> {
  return queryClient.resetQueries({ queryKey })
}

export function buildFiltersQueryKey(filters: object): string[] {
  return Object.keys(filters || {}).map((key) => (
    `${key}=${filters[key as never] as never}`
  ))
}
