import React from 'react'
import { Outlet } from 'react-router-dom'
import { OauthTokenType } from '@oi/constants/auth'
import { createCaslAbilityBuilder } from '@oi/node/auth'
import { useActiveUser } from '@oi/react/modules/api'

import { AbilityContext } from './ability'

export default function AbilityProvider() {
  const activeUser = useActiveUser()

  const userAbilities = React.useMemo(() => {
    if (activeUser) {
      return createCaslAbilityBuilder(activeUser.owner, activeUser.admin, OauthTokenType.FirstParty)
    }

    return createCaslAbilityBuilder(false, false, OauthTokenType.FirstParty)
  }, [activeUser])

  return (
    <AbilityContext.Provider value={userAbilities}>
      <Outlet />
    </AbilityContext.Provider>
  )
}
