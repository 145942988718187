import type { CheckoutApiCheckoutRequest, CheckoutComplete } from './open-api'

import { checkoutApi } from './api.constants'
import { useWrapMethod } from './api.internal'

export function useCheckoutMutation() {
  return useWrapMethod<
    CheckoutApiCheckoutRequest,
    CheckoutComplete
  >(
    (params: CheckoutApiCheckoutRequest) => checkoutApi.checkout(params)
  )
}
